import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { FeaturedPost, GridItemPost, Layout } from '../../components'
import './categories.scss'
import { capitalize } from '../../utils'

const Categories = ({ data, location, pageContext }) => {
  const { title, siteUrl } = data.site.siteMetadata
  const current = data.currentCategory.edges[0].node
  const { edges } = data.categoryPosts
  const featuredPost = edges.filter(i => i.node.fields.slug === current.featuredSlug)[0].node

  if (edges) {
    return (
      <Layout
        pathPrefix={ data.site.pathPrefix }
        pathName={ location.pathname }
      >
        <Helmet
          htmlAttributes={ { lang: 'en' } }
          meta={ [
            { name: 'description', content: current.tagline },
            { property: 'og:locale', content: 'en_US' },
            { property: 'og:type', content: 'blog' },
            { property: 'og:title', content: `${capitalize(current.id)} - ${title}` },
            { property: 'og:description', content: current.tagline },
            { property: 'og:url', content: siteUrl },
            { property: 'og:site_name', content: title },
            { property: 'article:publisher', content: 'https://signalsciences.com' },
            { property: 'article:section', content: current.id },
            { property: 'og:image', content: `${siteUrl}${featuredPost.frontmatter.featuredImage.childImageSharp.sizes.src}` },
            { property: 'twitter:card', content: 'summary_large_image' },
            { property: 'twitter:description', content: current.tagline },
            { property: 'twitter:title', content: `${capitalize(current.id)} - ${title}` },
            { property: 'twitter:site', content: '@signalsciences' },
            { property: 'twitter:image', content: `${siteUrl}${featuredPost.frontmatter.featuredImage.childImageSharp.sizes.src}` },
            { property: 'twitter:creator', content: '@signalsciences' },
          ] }
          link={ [{ rel: 'canonical', href: `${siteUrl}/categories/${current.id}` }] }
          title={ `${current.tagline} | ${pageContext.siteTitle}` }
        />
        <div className='PageContainer Categories'>
          <div className='CategoryInfo'>
            <h3 className='CategoryHeading'>
              <span className='CategoryEmoji'>{ current.emoji }</span>
              <span className='CategoryName'>{ current.id }</span>
            </h3>
            <p className='CategoryTagline'>{ current.tagline }</p>
          </div>
          { featuredPost && <FeaturedPost post={ featuredPost } featuredIn /> }
          <p className='GridHeader'>Recent Articles</p>
          <section className='Grid'>
            { edges.map(post => post.node.fields.slug !== current.featuredSlug && (
              <GridItemPost
                key={ post.node.fields.slug }
                post={ post.node }
                defaultImage={ data.defaultImage }
                showCategories={ false }
              />
            )) }
          </section>
        </div>
      </Layout>
    )
  }
}

export default Categories

export const pageQuery = graphql`
  query($category: String!) {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
      pathPrefix
    }
    currentCategory: allCategoryYaml(filter: { id: { eq: $category } }) {
      edges {
        node {
          id
          emoji
          tagline
          featuredSlug
        }
      }
    }
    defaultImage: file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 760) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    categoryPosts: allMarkdownRemark(
      filter: { frontmatter: { category: { eq: $category } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author {
              id
              bio
              url
            }
            category
            excerpt
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 760) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`
